import React, {useEffect, useState, useRef} from 'react';


const BestaatNiet: React.FC = () => {

    return (
        <div className='h-[30rem] bg-[#fffcf5]'>
            <div className='flex flex-col items-center justify-center pt-52'>
                <div className='text-8xl'>Oops...</div>
                <div className=''>Deze pagina bestaat nog niet</div>
                <div className=''>Maar de developer is er kei hard mee bezig!</div>
            </div>
        </div>
    )
};

export default BestaatNiet;
